import Vue from 'vue'
import VueRouter from 'vue-router'
import { bvkExaminationRegisterRoutes } from './examination-register-routes'
import { hodoRoutes } from './hodo-routes'

Vue.use(VueRouter)

const routes = { hodoRoutes, bvkExaminationRegisterRoutes }

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.path !== from.path) {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  // base: process.env.VUE_APP_BASE_URL,
  routes:
    process.env.VUE_APP_EXAMINATION_REGISTER_BVK === 'true'
      ? routes.bvkExaminationRegisterRoutes
      : routes.hodoRoutes
})

export default router
